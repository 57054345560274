body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  width: 1200px;
  margin: auto;
}

.same-btn {
  width: 217px;
  height: 60px;
  background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
  border-radius: 30px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  border: none;

  &:disabled {
    cursor: no-drop;
    background-image: none;
    background: #707d97 !important;

    &:active {
      opacity: 1;
    }
  }
}
.ant-btn:disabled {
  cursor: no-drop;
  background-image: none;
  background: #707d97 !important;

  &:active {
    opacity: 1;
  }
}
@media (max-width: 1000px) {
  #root {
    overflow: hidden;
  }

  .container {
    width: 100%;
    margin: auto;
    padding: 0 16px;
  }
}