.footer{
    background: rgba(18, 18, 18, 1);
    &>.container{
        &>.block1{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px 0;
            border-bottom: 1px solid rgba(255,255,255,0.3);
            &>.left{
                 display: flex;
                 align-items: center;
    
                &>img{
                    width: 100px;
                    margin-right: 20px;
                }
                &>.content{
                    max-width: 580px;
                    &>div:first-child{
                        font-size: 18px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                    &>div:last-child{
                        font-size: 12px;
                        color: rgba(255, 255, 255, 0.60);

                    }
                }
            }
            &>.right{
                &>a{
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                }
    
            }
        }
        &>.copyright{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
font-family: DIN-Regular, DIN;
font-weight: 400;
color: rgba(255,255,255,0.8);
padding: 30px 0;
&>.imgs{
    &>a{
        
        &>img{
        width: 24px;
        }
        
    }
    &>a:not(:last-child){
        margin-right: 30px;
    }
}
        }
    }
}

@media(max-width:768px) {
    .footer{
        &>.container{
            &>.block1{
                border: none;
                &>.left{
                    &>.content{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }
                }
            }
            &>.copyright{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}