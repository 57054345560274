.claimRecord {
    width: 775px !important;

    &>.ant-modal-content {
        padding: 0;
        border-radius: 24px;
        border: 1px solid #AF00E5;
        background: #000000;
        &>.ant-modal-close {
            top: 24px;
            color: #333;
            &>.ant-modal-close-x{
                &>span{
                    &>svg{
                        width: 24px;
                        color: #AB00E0;
                    }
                }
            }
        }

        &>.ant-modal-header {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #000;
            border-radius: 24px 24px 0px 0px;
            margin-bottom: 0;
            padding: 24px 25px;
            border-bottom :1px solid #AF00E5;

            &>.ant-modal-title {
                font-size: 20px;
                color: #fff;
                font-weight: 600;
            }
        }

        &>.ant-modal-body {
            
            padding: 25px 24px 27px;
        }
    }

    .box {
        &>.ant-row{
            &>.ant-col {
                text-align: center;
            }
        }
        &>.tabs {
            display: flex;
            margin-bottom: 20px;

            &>div {
                color: #fff;
                padding: 10px 20px;
                background: #707d97;
                margin-right: 10px;
                border-radius: 30px;
                cursor: pointer;

                &.active {
                    background: linear-gradient(90deg, #FC6713 0%, #C93731 100%);

                }
            }
        }

        &>.top {
            height: 60px;
            // background:rgba(247, 247, 247, 1);
            align-items: center;
            border: 1px solid #AF00E5;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            padding: 0 24px ;
           
        }

        &>.childrenList {
            height: 316px;
            border: 1px solid #AF00E5;
            
            margin-top: -1px;

            &>.lists {
                font-size: 18px;
                font-weight: 300;
                color: #FFFFFF;
                height: 316px;
                overflow: hidden auto;

                &>.item {
                    padding: 18px 24px;
                    border-bottom: 1px solid  rgba(221, 221, 221, 1);
                    text-align: center;
                    &>.ant-col:nth-child(1){
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #fff;
                    }
                    &>.ant-col:nth-child(2){
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #fff;
                    }
                    &>.ant-col:nth-child(3){
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #fff;
                    }
                }
            }

            &>.lists::-webkit-scrollbar {
                width: 10px;
            }

            &>.lists::-webkit-scrollbar-track {
                background: none;

            }

            &>.lists::-webkit-scrollbar-thumb {
                background: #021B3B;
            }

            .nolists {
                font-size: 16px;
                text-align: center;
                padding-top: 63px;

                &>img {
                    width: 63px;
                }
            }
        }
    }
}


@media(max-width:1000px) {
    .claimRecord {
        &>.ant-modal-content {
            border: 1px solid #AF00E5;
            background: #000000;
            &>.ant-modal-close {
                top: 24px;
                color: #333;
                &>.ant-modal-close-x{
                    &>span{
                        &>svg{
                            width: 24px;
                            color: #fff;
                        }
                    }
                }
            }
            &>.ant-modal-header {
                background: #000;
                justify-content: center;
                border: none;
                &>.ant-modal-title {
                    font-size: 16px;
                    color: #fff;
                }
            }

            &>.ant-modal-body {
                padding: 0 15px 49px;
                color: #999999;
                &>.box{
                    &>.top{
                        background: none;
                        border: none;
                        border-bottom: 1px solid #4D4D4D;
                        padding: 0;
                    }
                    &>.childrenList{
                        border: none;
                        &>.lists{
                              &>.item {
                                padding:8px 0;
                                border-bottom:none;
                                display: flex;
                                align-items: center;
                                &>.ant-col:nth-child(1){
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #fff;
                                }
                                &>.ant-col:nth-child(2){
                                    font-size: 12px;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                     color: #fff;
                                }
                                &>.ant-col:nth-child(3){
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #999999;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                
            }
        }

        .box {
            &>.tabs {
                font-size: 12px;
            }
        }
    }
}