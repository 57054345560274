.language {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF !important;


    &>.ant-select-selector {
        width: 102px;
        background-color:transparent !important;
        color: #FFFFFF;
        border: 1px solid rgba(255, 255, 255, 1) !important;
        border-radius: 20px;
        .ant-select-selection-item{
            display: flex;
    align-items: center;
            color: #fff;
            &>img{
                width: 24px;
                margin-right: 5px;
            }
        }
    }
    &>.ant-select-arrow{
        color: #fff;
    }
}

.ant-select-item-option-content{
    display: flex;
    align-items: center;
    &>img{
        width: 24px;
        margin-right: 5px;
    }
}
@media(max-width:768px) {
    .languages{
        position: fixed;
        bottom: 0;
        padding: 20px;
        border-top: 1px solid #4D4D4D;
        width: 100%;
        display: flex;
        // justify-content: space-between;
        &>.imgaddtext{
            color: #fff;
            &>img{
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
            display: flex;
            align-items: center;
            margin-right: 30px;
        }
        &>.language{
            display: flex;
            align-items: center;
        }
    }
}