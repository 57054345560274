.claimRecords {
    width: 629px !important;

    &>.ant-modal-content {
        padding: 0;
        border-radius: 20px;
        border: 1px solid #AB00E0;
        background: #000;
        &>.ant-modal-close {
            top: 15px;
            color: #AB00E0;
        }

        &>.ant-modal-header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 58px;
            background: #000;
            border-bottom: 1px #AB00E0 solid;
            margin-bottom: 0;
            border-radius: 20px 20px 0 0;
            &>.ant-modal-title {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
            }
        }

        &>.ant-modal-body {
            // background: rgba(5, 44, 85, 1);
            padding: 62px 50px 59px;

            &>.box {
                display: flex;
                flex-direction: column;
                align-items: center;
            
                &>.rule{
                    font-size: 16px;
                    font-family: PingFang SC-Bold, PingFang SC;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                &>.same-btn{
                    width: 168px;
                    height: 50px;
                    font-size: 18px;
                    background: linear-gradient(92deg, #F6C518 0%, #B705E0 100%);
                    border-radius: 25px 25px 25px 25px;
                    margin-top: 62px;
                }
        
               
            }
        }
    }

    
}