.circle-container {
    /* 圆环 */
    position: relative;
    width: 206px;
    height: 206px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 206px;
        height: 206px;
        border-radius: 50%;
        background: linear-gradient(0deg, rgb(255, 220, 0) 0%, rgb(175, 0, 229) 100%);
        -webkit-mask: radial-gradient(transparent, transparent 97px, #000 97px);
        mask: radial-gradient(transparent 97px, #000 97px);
    }

    .circle-text {
        position: relative;
        z-index: 1;
        text-align: center;
        font-size: 36px;
        font-weight: 900;
        color: #FFFFFF;
    }
    .circle-text1 {
        position: relative;
        z-index: 1;
        text-align: center;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 900;
        color: #FFFFFF;
    }

}

@keyframes rotate {
    from {
        transform: rotate(0deg);
        /* 起始角度为0度 */
    }

    to {
        transform: rotate(360deg);
        /* 终止角度为360度，即一圈 */
    }
}

.same-qiu {
    position: absolute;

    &>img {
        width: 193px;
        animation: rotate 10s infinite linear;
        /* 设置旋转动画 */
    }
}

.home {
    // background: linear-gradient(180deg, #000000 0%, #121212 100%);
    background-image: url('../../assets//images/body-bg.png');
    background-size: cover;
    position: relative;

    &>img {
        margin-top: -94px;
    }

    .same-qiu {

        top: 100px;
        left: 5%;
    }

    .qiu2 {
        top: 12%;
        right: 10%;
        left: inherit;

        &>img {
            animation: rotate 13s infinite linear;
        }
    }

    &>.content {
        text-align: center;
        margin-top: -250px;

        &>.title {
            font-size: 48px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 67px;
            margin-bottom: 13px;
        }

        &>.subtitle {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 24px;
        }

        &>.go {
            display: flex;
            align-items: center;
            justify-content: center;

            &>.ant-btn {
                width: 198px;
                height: 60px;
                background: #AF00E5;
                border-radius: 30px;
                font-size: 18px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                border: none;
                margin-right: 44px;
            }

            &>.border1 {
                position: relative;
                width: 92px;
                height: 92px;
                background: rgba(175, 0, 229, 0.6);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &>.border2 {
                    width: 62px;
                    height: 62px;
                    background: #AF00E5;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>img {
                        width: 32px;
                    }
                }
            }
        }
    }

    .same-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 60px;

        &>img {
            width: 64px;
        }

        &>div {
            font-size: 48px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
            margin: 0 16px;
        }
    }

    .game {
        margin-top: 136px;

        position: relative;

        &>.qiu3 {
            right: 1%;
            left: inherit;
            top: 45%;

            &>img {
                animation: rotate 20s infinite linear;
            }
        }

        &>.container {
            &>.tabs {

                &>.tab {
                    display: flex;

                    justify-content: space-between;

                    &>.tab-item {
                        display: flex;
                        align-items: center;
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin-bottom: -2px;

                        &>div {
                            padding: 18px 72px;
                            cursor: pointer;
                        }

                        &>.active {
                            border-width: 2px;
                            border-style: solid;
                            border-top-color: #AF00E5;
                            border-left-color: #AF00E5;
                            border-right-color: #AF00E5;
                            border-bottom-color: #000000;
                            border-radius: 10px 10px 0 0;
                        }
                    }

                    &>.tab-icon {
                        display: flex;
                        align-items: center;

                        &>img {
                            width: 42px;
                            cursor: pointer;
                        }

                        &>img:first-child {
                            margin-right: 28px;

                        }
                    }
                }

                &>.tab-content {
                    border: 2px solid #AF00E5;
                    background: linear-gradient(180deg, #000000 0%, #121212 100%);
                    border-radius: 0 30px 30px 30px;

                    &>.block1 {
                        text-align: center;
                        padding: 60px 30px;

                        &>.imgs {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &>div {
                                margin: 0 15px;
                            }

                            &>.circle-container {
                                width: 102px;
                                height: 102px;

                                &>.circle-text {
                                    width: 102px;
                                    height: 102px;
                                    position: absolute;
                                    top: 11px;
                                    left: 0;

                                    &>.other-img {
                                        width: 80px;
                                        height: 80px;
                                        margin: auto;
                                        position: relative;display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        &>img {
                                            width: 80px;
                                            // margin: 0 15px;
                                        }
                                    }
                                }

                                &>.circle {
                                    width: 90px;
                                    height: 90px;
                                    -webkit-mask: radial-gradient(transparent, transparent 43px, #000 44px);
                                    mask: radial-gradient(transparent 43px, #000 44px);
                                }
                            }

                            &>.same-img {
                                width: 102px;
                                height: 102px;
                                display: flex;
                                align-items: center;

                                &>img {
                                    position: relative;
                                    width: 80px;
                                    height: 80px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;


                                }
                            }
                        }

                        &>.current {
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            margin: 40px 0;
                        }

                        &>.next {
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            margin-top: 36px;
                            margin-bottom: 19px;

                        }

                        &>.amount {
                            font-size: 36px;
                            font-family: DIN-Bold, DIN;
                            font-weight: bold;
                            color: #FFFFFF;
                        }

                        &>.ant-btn {
                            width: 217px;
                            height: 60px;
                            background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                            border-radius: 30px;
                            border: none;
                            font-size: 24px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                            margin-top: 48px;
                            margin-bottom: 20px;
                        }

                        &>.balance {
                            font-size: 18px;
                            font-family: DIN-Bold, DIN;
                            font-weight: bold;
                            color: rgba(255, 255, 255, 0.6);
                        }

                        .cup {
                            padding-top: 29px;
                            font-size: 36px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;

                            &>.cup-bg {
                                background: url('../../assets//images//cup-bg.png');
                                width: 206px;
                                height: 206px;
                                background-size: cover;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin: auto;

                                &>img {
                                    width: 136px;
                                }
                            }
                        }
                    }

                    &>.block2 {
                        position: relative;
                        padding: 30px 30px;

                        &>.records {
                            position: absolute;
                            right: 30px;
                            font-size: 18px;
                            font-family: AlibabaPuHuiTi_2_55_Regular;
                            color: #FFFFFF;
                            cursor: pointer;

                            &>span {
                                margin-right: 5px;
                            }

                        }

                        &>.reward {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 40px;
                            padding: 0 58px;
                            text-align: center;



                            &>.box1 {
                                &>.content {
                                    margin-top: 28px;
                                    font-size: 18px;
                                    font-family: AlibabaPuHuiTi_2_55_Regular;
                                    color: #FFFFFF;

                                    &>.anticon {
                                        padding: 5px;
                                        margin-left: 5px;
                                    }
                                }
                            }

                            &>.box2 {
                                font-size: 18px;
                                font-family: AlibabaPuHuiTi_2_55_Regular;
                                color: #FFFFFF;
                               
                            }

                            &>.box3 {
                                &>.ant-btn {
                                    width: 100px;
                                    height: 33px;
                                    background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                                    font-size: 16px;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #FFFFFF;
                                    border-radius: 30px;
                                    border: none;
                                }
                                &>.border {
                                    margin-bottom: 28px;
                                    position: relative;
                                    width: 206px;
                                    height: 206px;
                                    border-radius: 100%;
                                    background: linear-gradient(0deg, rgb(255, 220, 0) 0%, rgb(175, 0, 229) 100%);
                                    border-width: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &>.border1 {
                                        width: 196px;
                                        height: 196px;
                                        border-radius: 100%;
                                        background: #000000;
                                    }
                                }

                               
                            }
                        }
                    }

                    &>.block3 {

                        &>.nobind {
                            text-align: center;
                            padding: 72px;

                            .ant-descriptions-item-container {
                                align-items: center;

                                &>.ant-descriptions-item-label {
                                    font-size: 18px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                }

                                &>.ant-descriptions-item-content {
                                    &>.ant-input {
                                        height: 50px;
                                        background: #121212;
                                        border-radius: 8px;
                                        border: 1px solid rgba(255, 255, 255, 0.1);
                                        font-size: 18px;
                                        font-family: DIN-Bold, DIN;
                                        font-weight: bold;
                                        color: #FFFFFF;
                                    }
                                    &>.ant-input::placeholder {
                                        font-size: 16px;
                                        color: #999999;
                                      }
                                }
                            }

                            &>.same-btn {
                                margin-top: 40px;
                            }
                        }

                        &>.bind {
                            padding: 63px;

                            &>.top {
                                display: flex;
                                justify-content: space-between;
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;

                                &>.left {
                                    &>.myparent {
                                        &>.anticon {
                                            color: rgba(255, 220, 0, 1);
                                            margin-left: 10px;
                                        }
                                    }

                                    &>.invite-line {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 40px;

                                        &>.border {
                                            // width: 700px;
                                            height: 50px;
                                            background: #121212;
                                            border-radius: 8px;
                                            border: 1px solid rgba(255, 255, 255, 0.1);
                                            padding: 14px 17px;

                                            &>.anticon {
                                                color: rgba(255, 220, 0, 1);
                                                margin-left: 10px;
                                            }
                                        }
                                    }
                                }

                                &>.right {
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    &>.circle-container {
                                        width: 120px;
                                        height: 120px;

                                        &>.circle {
                                            width: 120px;
                                            height: 120px;
                                            -webkit-mask: radial-gradient(transparent, transparent 55px, #000 55px);
                                            mask: radial-gradient(transparent 55px, #000 55px);
                                        }
                                    }
                                    &>.ant-btn {
                                        width: 100px;
                                        height: 33px;
                                        background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                                        font-size: 16px;
                                        font-family: PingFangSC-Semibold, PingFang SC;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        border-radius: 30px;
                                        border: none;
                                    }
                                }
                            }

                            &>.same-line {
                                margin-top: 30px;
                                margin-bottom: 36px;
                            }

                            &>.table {
                                &>.top {
                                    height: 50px;
                                    background: #121212;
                                    font-size: 18px;
                                    font-family: AlibabaPuHuiTi_2_55_Regular;
                                    color: rgba(255, 255, 255, 0.6);
                                    padding: 0 11px;
                                    align-items: center;

                                    &>.ant-col:nth-child(2) {
                                        text-align: center;
                                    }

                                    &>.ant-col:nth-child(3) {
                                        text-align: right;
                                    }
                                }

                                &>.childrenList {
                                    &>.lists {
                                        max-height: 600px;
                                        overflow: auto;
                                        padding-top: 10px;

                                        &>.ant-row {
                                            padding: 10px 11px;
                                            align-items: center;
                                            &>.ant-col {
                                                font-size: 18px;
                                                font-family: DIN-Bold, DIN;
                                                font-weight: bold;
                                                color: #FFFFFF;
                                            }

                                            &>.ant-col:nth-child(2) {
                                                text-align: center;
                                                &>img{
                                                    width: 20px;
                                                    height: 20px;
                                                    margin: 0 5px;
                                                }
                                            }

                                            &>.ant-col:nth-child(3) {
                                                text-align: right;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }

                &>.isActive1 {
                    border-radius: 30px;
                }
            }
        }
    }

    .friend {
        position: relative;
        padding-top: 100px;

        .same-qiu {
            top: 40px;

            &>img {
                animation: rotate 13s infinite linear;
            }
        }

        &>.container {
            &>.icons {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 50px;

                &>img {
                    width: 175px;
                    margin: 0 32px;
                }
            }
        }
    }

}


/* 水波纹 */
.border2:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: ripple 1s ease-out;
}

.other-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -9px;
    left: -8px;
    background-image: radial-gradient(circle, rgba(255, 220, 0, .5) 0%, rgba(175, 0, 229, .9) 80%);
    border-radius: 50%;
    animation: ripple 2s ease-out infinite;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.same-line {
    height: 2px;
    background-image: repeating-linear-gradient(to right, #AF00E5, #AF00E5 9px, transparent 10px, transparent 20px);
}

.ant-tooltip {
    &>.ant-tooltip-arrow {
        --antd-arrow-background-color: #fff;
    }

    &>.ant-tooltip-content {
        &>.ant-tooltip-inner {
            background-color: #FFFFFF;

            &>.tips {
                padding: 5px 10px 17px;

                &>.title {
                    font-size: 12px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #AF00E5;
                    margin-bottom: 8px;
                }

                &>.content {
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #121212;

                    &>span {
                        color: rgba(175, 0, 229, 1);
                    }
                }
            }
        }
    }
}

#liquidfill-chart {
    width: 100%;
    height: 196px;
    overflow: visible;
    margin-bottom: 20px;
    z-index: 1;
}