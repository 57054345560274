@import '../../styles/variable.scss';

.main-header {
    width: 100%;
    background: $header_bg;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.30);
    z-index: 1;

    &>.container {

        &>header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &>.logo {
                &>img {
                    width: 70px;
                }
            }

            &>.right {
                display: flex;

                .pc_menu {
                    background: $header_bg;
                    color: $menu_color;
                    font-size: 18px;

                    font-weight: 600;

                    &>.ant-menu-overflow-item {
                        padding-inline: 50px;
                    }

                    .ant-menu-item.ant-menu-item-selected,
                    .ant-menu-item.ant-menu-item-active {
                        background-color: $menu_bg;
                        color: $menuitem_active
                    }

                    .ant-menu-item.ant-menu-item-selected::after,
                    .ant-menu-item.ant-menu-item-active::after {
                        border-bottom-color: $menuitem_border;
                    }

                    .ant-menu-submenu.ant-menu-submenu-selected,
                    .ant-menu-submenu.ant-menu-submenu-active {
                        background: $menu_bg;

                        &>.ant-menu-submenu-title {
                            &>.ant-menu-title-content {
                                color: $primary_color;
                            }
                        }
                    }

                    .ant-menu-submenu.ant-menu-submenu-selected::after,
                    .ant-menu-submenu.ant-menu-submenu-active::after {
                        border-bottom-color: $menuitem_border;

                    }
                }

                .hd_wallet {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    .wallet_address{
                        margin-right: 10px;
                    }
                    .ConnectWallet {
                        margin-right: 16px;
                        margin-left: 50px;

                        &>.ant-btn {
                            
                            height: 40px;
                            background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                            border-radius: 20px;
                            font-size: 16px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #FFFFFF;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.ant-menu-submenu-popup {
    &>.ant-menu-sub {
        &>.ant-menu-item {}
    }
}

@media(max-width:768px) {
    .drawer-mask {
        background: #000;
        opacity: 0;
        width: 100%;
        height: 0;
        position: fixed;
        top: 0;
        left: 0;
        transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), height 0s ease 0.3s;
        z-index: 99;
    }

    .drawer-show {
        opacity: 0.6;
        height: 100%;
        transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .mobile-header {


        &>header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #000;
            padding: 15px 10px;

            &>img {
                width: 30px;

            }

            &>.hd_wallet {
                color: #fff;
                
                .ConnectWallet {
                    &>.ant-btn {
                        border-radius: 10px;
                        height: 0 26px;
                        color: #FFFFFF !important;
                        border: 1px solid $primary_color;
                        font-size: 12px;
                        background: $primary_color !important;
                    }
                }
            }

            .mobile-menu-img {
                width: 20px;
            }
        }
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        right: -100%;
        width: 250px;
        height: 100%;
        z-index: 999;
        background-color: #262626;
        transition: all 0.5s ease-out;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        height: 100%;
        overflow: initial;

        &>.ant-menu {
            background: none;

            &>.ant-menu-item {
                color: #fff;

                &>img {
                    width: 20px;
                    height: 20px;
                }
            }

            .ant-menu-item.ant-menu-item-selected,
            .ant-menu-item.ant-menu-item-active {
                background-color: $menu_bg;
                color: $menuitem_active
            }

            &>.ant-menu-submenu {
                color: #fff;

                &>div {
                    color: #fff !important;

                    &>img {
                        width: 20px;
                        height: 20px;
                    }
                }

                &>.ant-menu {
                    &>.ant-menu-item {
                        color: #fff;

                    }
                }

            }
        }
    }

    .show-menu {
        right: 0;
    }

    .logo {
        padding: 20px;
        border-bottom: 1px solid #4D4D4D;

        &>img {
            width: 66px;
            height: 66px;
        }
    }
}