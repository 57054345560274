.binding {
    width: 629px !important;

    &>.ant-modal-content {
        padding: 0;
        border-radius: 20px;
        border: 1px solid #AB00E0;
        background: #000;
        &>.ant-modal-close {
            top: 15px;
            color: #AB00E0;
        }

        &>.ant-modal-header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 58px;
            background: #000;
            // border-radius: 10px 10px 0px 0px;
            border-bottom: 1px #AB00E0 solid;
            margin-bottom: 0;
            border-radius: 20px 20px 0 0;

            &>.ant-modal-title {
                font-size: 16px;
                font-weight: 600;
                color: #fff;
            }
        }

        &>.ant-modal-body {
            padding: 62px 50px 59px;

            &>.box {
                display: flex;
                flex-direction: column;
                align-items: center;
                &>.ant-input{

                    border: 1px solid #CC1CF7;
                    background: none;
                    color: #fff;
                    font-size: 16px;
                    padding: 10px;
                }
                &>.ant-input::placeholder {
                    font-size: 16px;
                    color: #999999;
                  }
                &>.same-btn{
                    width: 168px;
                    height: 50px;
                    font-size: 18px;
                    background: linear-gradient(92deg, #F6C518 0%, #B705E0 100%);
                    border-radius: 25px 25px 25px 25px;
                    margin-top: 62px;
                }
        
               
            }
        }
    }

    
}