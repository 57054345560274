@media (max-width: 1000px) {
    .circle-container {
        /* 圆环 */
        position: relative;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;

        .circle {
            position: absolute;
            top: 0;
            left: 0;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: linear-gradient(0deg, rgb(255, 220, 0) 0%, rgb(175, 0, 229) 100%);
            -webkit-mask: radial-gradient(transparent, transparent 37px, #000 37px);
            mask: radial-gradient(transparent 37px, #000 37px);
        }

        .circle-text {
            position: relative;
            z-index: 1;
            text-align: center;
            font-size: 12px;
            font-weight: 900;
            color: #FFFFFF;
        }
    }

    .home {

        &>img {
            margin-top: 0;
        }

        &>.content {
            margin-top: -123px;
            &>.title {
                font-size: 24px;
                line-height: 33px;
            }
            &>.subtitle{
                margin: 24px 0;
            }
            &>.go {

                &>.ant-btn {
                    margin-right: 10px;
                }
                &>.border1 {
                    position: relative;
                    width: 70px;
                    height: 70px;
                    background: rgba(175, 0, 229, 0.6);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    &>.border2 {
                        width: 40px;
                        height: 40px;
                        background: #AF00E5;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &>img {
                            width: 32px;
                        }
                    }
                }
            }
        }

        .game {
            margin-top: 60px;
            margin-bottom: 0;

            &>.container{
                &>.same-title {
                    margin-bottom: 24px;
    
                    &>div {
                        font-size: 24px;
                        margin: 0 12px;
                    }
                }
    
                &>.tabs {
                    &>.tab {
                        &>.tab-item {
                            width: 100%;
                            font-size: 16px;
    
                            &>div {
                                padding: 12px 0;
                                width: 100%;
                                cursor: pointer;
                                text-align: center;
                            }
                        }
                    }
    
                    &>.tab-content {
                        border: 2px solid #AF00E5;
                        background: linear-gradient(180deg, #000000 0%, #121212 100%);
                        border-radius: 0 0 30px 30px;
    
                        &>.block1 {
                            text-align: center;
                            padding: 47px 19px;
    
                            &>.partake {
                                &>div {
                                    display: flex;
                                    align-items: center;
    
                                    &>span {
                                        color: #9B9B9B;
                                    }
    
                                    &>.imgs {
                                        
    
                                        display: flex;
                                        align-items: center;
    
                                        &>div {
                                            margin: 0 5px;
                                        }
    
                                        &>.circle-container {
                                            width: 34px;
                                            height: 34px;
    
                                            &>.circle-text {
                                               
    
                                                &>.other-img {
                                                    width: 24px;
                                                    height: 24px;
                                                    margin: auto;
                                                    position: relative;
    
                                                    &>img {
                                                        width: 24px;
                                                    }
                                                }
                                            }
    
                                            &>.circle {
                                                width: 29px;
                                                height: 28px;
                                                -webkit-mask: radial-gradient(transparent, transparent 10px, #000 15px);
                                                mask: radial-gradient(transparent 15px, #000 15px);
                                            }
                                        }
    
                                        &>.same-img {
                                            width: 24px;
                                            height: 24px;
                                            display: flex;
                                            align-items: center;
    
                                            &>img {
                                                position: relative;
                                                width: 24px;
                                                height: 24px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
    
    
                                            }
                                        }
    
                                    }
                                }
    
                                &>.Illuminate {
                                    margin-top: 27px;
                                    display: flex;
                                    justify-content: space-between;
                                    color: #9B9B9B;
    
                                    &>.amount {
                                        color: #FFFFFF;
                                        font-weight: bold;
                                    }
                                }
                            }
    
                            &>.ant-btn {
                                width: 100%;
                                height: 50px;
                                background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                                border-radius: 30px;
                                border: none;
                                font-size: 16px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFFFFF;
                                margin-top: 28px;
                                margin-bottom: 20px;
                            }
    
                            &>.balance {
                                font-size: 12px;
                                font-family: DIN-Bold, DIN;
                                font-weight: bold;
                                color: rgba(255, 255, 255, 0.6);
    
                                &>.amount {
                                    color: #fff;
                                }
                            }

                            &>.cup{
                                font-size: 15px;
                                &>img{
                                    width: 80px;
                                    height: 80px;
                                }
                            }
                        }
    
                        &>.block2 {
                            position: relative;
                            padding: 30px 26px;
    
                            &>.records {
                                position: revert;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 32px;
    
                                &>span {
                                    margin-right: 5px;
                                }
    
                            }
    
                            &>.reward {
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
                                margin-top: 30px;
                                padding: 0 0;
                                text-align: center;
    
                                &>.box1 {
                                    &>.content {
                                        margin-top: -3px;
                                        font-size: 12px;
                                        font-family: AlibabaPuHuiTi_2_55_Regular;
                                        color: #FFFFFF;
    
                                        &>.anticon {
                                            padding: 5px;
                                            margin-left: 5px;
                                        }
                                    }
                                  
                                }
    
                                &>.box2 {
                                    font-size: 12px;
                                    font-family: AlibabaPuHuiTi_2_55_Regular;
                                    color: #FFFFFF;
    
                                }
    
                                &>.box3 {
                                      &>.ant-btn {
                                        width: 80px;
                                        height: 24px;
                                        background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                                        font-size: 12px;
                                        font-family: PingFangSC-Semibold, PingFang SC;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        border-radius: 30px;
                                        border: none;
                                    }
                                    &>.border {
                                        position: relative;
                                        width: 80px;
                                        height: 80px;
                                        border-radius: 100%;
                                        background: linear-gradient(0deg, rgb(255, 220, 0) 0%, rgb(175, 0, 229) 100%);
                                        border-width: 20px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
    
                                        &>.border1 {
                                            width: 75px;
                                            height: 75px;
                                            border-radius: 100%;
                                            background: #000000;
    
                                            &>#liquidfill-chart {
                                                height: 75px !important;
    
                                                &>div {
                                                    width: 75px !important;
                                                    height: 75px !important;
    
                                                    &>canvas {
                                                        width: 75px !important;
                                                        height: 75px !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                   
                                }
                            }
    
    
                        }
    
                        &>.block3 {
    
                            &>.nobind {
                                text-align: center;
                                padding: 24px;
    
                                .ant-descriptions-item-container {
                                    align-items: center;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: baseline;
    
                                    &>.ant-descriptions-item-label {
                                        font-size: 12px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        color: #FFFFFF;
                                        margin-bottom: 20px;
                                    }
    
                                    &>.ant-descriptions-item-content {
                                        width: 100%;
    
                                        &>.ant-input {
                                            height: 50px;
                                            background: #121212;
                                            border-radius: 8px;
                                            border: 1px solid rgba(255, 255, 255, 0.1);
                                            font-size: 12px;
                                            font-family: DIN-Bold, DIN;
                                            font-weight: bold;
                                            color: #FFFFFF;
                                        }
                                    }
                                }
    
                                &>.same-btn {
                                    margin-top: 20px;
                                    height: 40px;
                                    font-size: 16px;
                                }
                            }
    
                            &>.bind {
                                padding: 24px;
    
                                &>.top {
                                    display: flex;
                                    justify-content: space-between;
                                    // align-items: center;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #FFFFFF;
    
                                    &>.left {
                                        &>.myparent {
                                            &>.anticon {
                                                color: rgba(255, 220, 0, 1);
                                                margin-left: 5px;
                                                &>svg{
                                                    width: 20px;
                                                    height: 20px;
                                                }
                                            }
                                        }
    
                                        &>.invite-line {
                                            display: flex;
                                            align-items: center;
                                            margin-top: 20px;
    
                                            &>.border {
                                                // width: 700px;
                                                height: 0;
                                                background: #000;
                                                border-radius: 8px;
                                                border: none;
                                                padding: 0;
                                                display: flex;
                                                align-items: center;
                                                &>.anticon {
                                                    color: rgba(255, 220, 0, 1);
                                                    margin-left: 5px;
                                                    &>svg{
                                                        width: 20px;
                                                        height: 20px;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                    &>.right {
                                        &>.circle-container {
                                            width: 60px;
                                            height: 60px;
    
                                            &>.circle {
                                                width: 60px;
                                                height: 60px;
                                                -webkit-mask: radial-gradient(transparent, transparent 27px, #000 27px);
                                                mask: radial-gradient(transparent 27px, #000 27px);
                                            }
                                        }
                                    }
                                }
                                
                                &>.Nodedividend{
                                    color: #fff;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    &>.two{
                                        margin: 0 10px;
                                    }
                                    &>.ant-btn {
                                        // width: 60px;
                                        height: 20px;
                                        background: linear-gradient(131deg, #FFDC00 0%, #AF00E5 100%);
                                        font-size: 12px;
                                        font-family: PingFangSC-Semibold, PingFang SC;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        border-radius: 30px;
                                        text-align: center;
                                        border: none;
                                        padding: 0 20px;
                                    }
                                }
    
                                &>.same-line {
                                    background-image: repeating-linear-gradient(to right, #fff, #fff 9px, transparent 10px, transparent 20px);
                                    margin-top: 20px;
                                    margin-bottom: 30px;
                                }
    
                                &>.table {
                                    &>.top {
                                        height: 50px;
                                        background: #000;
                                        font-size: 10px;
                                        font-family: AlibabaPuHuiTi_2_55_Regular;
                                        color: rgba(255, 255, 255, 0.6);
                                        padding: 0 11px;
                                        align-items: center;
    
                                        &>.ant-col:nth-child(2) {
                                            text-align: center;
                                           
                                        }
    
                                        &>.ant-col:nth-child(3) {
                                            text-align: right;
                                        }
                                    }
    
                                    &>.childrenList {
                                        &>.lists {
                                            padding-top: 0px;
    
                                            &>.ant-row {
                                                padding: 10px 4px;
                                                align-items: center;
                                                &>.ant-col {
                                                    font-size: 12px;
                                                    font-family: DIN-Bold, DIN;
                                                    font-weight: bold;
                                                    color: #FFFFFF;
                                                }
    
                                                &>.ant-col:nth-child(2) {
                                                    text-align: center;
                                                    &>img{
                                                        width: 15px;
                                                        height: 15px;
                                                        margin: 0 5px;
                                                    }
                                                }
    
                                                &>.ant-col:nth-child(3) {
                                                    text-align: right;
                                                }
                                            }
                                        }
                                    }
                                }
    
                            }
                        }
    
    
    
                    }
                }
            }
        }

        .friend {
            position: relative;
            padding-top: 0;
            &>.container{
                &>.same-title {
                    margin-bottom: 24px;
                    margin-top: 50px;
    
                    &>div {
                        font-size: 24px;
                        margin: 0 12px;
                    }
                }
    
                &>.icons {
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    margin-bottom: 20px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-row-gap: 20px;
                    grid-column-gap: 20px;
    
                    &>img {
                        width: 105px;
                        margin: 0;
    
                    }
                }
            }
        }
    }
}
.other-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -2px;
    left: -3px;
    background-image: radial-gradient(circle, rgba(255, 220, 0, .5) 0%, rgba(175, 0, 229, .9) 80%);
    border-radius: 50%;
    animation: ripple 2s ease-out infinite;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}